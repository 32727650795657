import styled from 'styled-components';

export const NavContainer = styled.nav`
  position: fixed;
  width: 100%;
  top: 0;
  background-color: white;
  z-index: 100;
  ul {
    li {
      a {
        color: #a88442;
        text-decoration: none;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
`;

export const NavLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  align-items: center;

  button {
    border: none;
    background: transparent;
    svg {
      color: #a88442;
    }
  }
  @media (min-width: 769px) {
    button {
      border: none;
      background: transparent;
      svg {
        color: #a88442;
      }
      display: none;
    }
  }
  @media (min-width: 1367px) {
    max-width: 1366px;
  }
  @media (min-width: 1921px) {
    max-width: 1920px;
  }
`;

export const NavLinks = styled.ul`
  display: ${({isMobile}) => isMobile ? 'grid' : 'none'};
  position: ${({isMobile}) => isMobile ? 'fixed' : 'relative'};
  top: ${({isMobile}) => isMobile ? '50px' : 'auto'};
  height: ${({isMobile}) => isMobile ? 'calc(100% - 50px)' : 'auto'};
  background-color: white;
  grid-gap: 20px;
  grid-template-rows: repeat(5, max-content);
  list-style: none;
  transform: translateX(${({isExpanded}) => isExpanded ? '0' : 'calc(-100% - 50px)'});
  margin: 0;
  padding: 20px;
  transition: transform 0.3s ease;
  
  @media (min-width: 769px) {
    grid-template-columns: repeat(5, max-content);
    grid-template-rows: none;
    padding: 0;
    display: ${({isMobile}) => isMobile ? 'none' : 'grid'};
    transform: translateY(0%);
  }
`;

export const SocialNavLinks = styled.ul`
  grid-template-columns: repeat(2, max-content);
  grid-gap: 20px;
  display: grid;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: 769px) {
  }
`;
