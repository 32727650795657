import React from 'react';
import { PriceList, WholesaleContainer } from './Wholesale.styles';

const Wholesale = () => {
  return (
    <WholesaleContainer>
      <PriceList>
        <li>
          <h3>Quantity</h3>
          <h3>Price</h3>
        </li>
        <li>
          <h3>50 - 99</h3>
          <h3>£16.41 per pack</h3>
        </li>
        <li>
          <h3>100 - 249</h3>
          <h3>£13.28 per pack</h3>
        </li>
        <li>
          <h3>250 - 449</h3>
          <h3>£12.06 per pack</h3>
        </li>
        <li>
          <h3>500 - 999</h3>
          <h3>£9.41 per pack</h3>
        </li>
        <li>
          <h3>1000 - 1999</h3>
          <h3>£8.74 per pack</h3>
        </li>
      </PriceList>
    </WholesaleContainer>
  );
};

export default Wholesale;
