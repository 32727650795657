import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  @font-face {
      font-family: 'Didot-italic';
      src: url('/font/Didot-Italic.woff2') format('woff2'),
          url('/font/Didot-Italic.woff') format('woff');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
  }


  body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
    background-color: #fef5f4;
    color: #504a32;
    font-family: 'Open Sans', sans-serif;
    padding-top: 50px;
  }
  *{
    box-sizing: border-box;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  h1 {
    margin-top: 0;
    color: #a88442;
    text-transform: uppercase;
  }

  .App {
    display: grid;
    grid-template-rows: max-content;
    min-height: calc(100vh - 50px);
    }
  `;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px;

  @media (min-width: 1367px) {
    max-width: 1366px;
  }
  @media (min-width: 1921px) {
    max-width: 1920px;
  }
`;
