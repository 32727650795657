import React from 'react';
import { FooterContainer } from './Footer.styles';

const Footer = () => {
  return (
    <FooterContainer>
      Company Registration number: 13625686
    </FooterContainer>
  );
};

export default Footer;