import styled from 'styled-components';
import { Container } from '../../App.styles';

export const ContactContainer = styled(Container)`
  position: relative;
  display: grid;
  grid-template-areas:
    'body'
    'image';
  flex-direction: column;

  @media (min-width: 769px) {
    flex-direction: row;
    grid-template-areas: 'image body';
    grid-auto-columns: 1fr;
  }
`;

export const ContactImgContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  grid-area: image;
  img {
    position: relative;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  @media (min-width: 769px) {
    width: 100%;
  }
`;

export const ContactBody = styled.address`
  width: 100%;
  padding: 20px;
  grid-area: body;
  div {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  img {
    width: 48px;
    color: #a88442;
    margin-right: 10px;
  }
  a {
    color: #a88442;
    text-decoration: none;
    font-size: 20px;
  }
`;
