import React from 'react';
import { AboutContainer, AboutImgContainer } from './About.styles';

const About = () => {
  return (
    <AboutContainer>
      <AboutImgContainer>
        <img alt="About" src="/img/about-1.jpg" />
      </AboutImgContainer>
      <span>
        <h1>About</h1>
        <p>
          Meet our founder Miranda Ighofose who began making smoothies for her family as an easy way to enjoy all their
          favourite fruits and ingredients. Sometimes it was so easy to forget some of the tasty combinations that had
          been made previously and Miranda decided to create an easy way to have all the recipes in once place. This led
          to the creation of her smoothie recipe cards.
          <br />
          <br />
          Ultimately it is a great way to enjoy easy no fuss smoothies with recipes made with simple ingredients. There
          are some naughty treats as well as healthy options the choice is yours.
          <br />
          <br />
          The Blend & Sip recipe cards are designed and produced in the United Kingdom. We work with FSC certified
          manufacturers that prioritise having the lowest impact on the environment. All FSC® materials are from a
          sustainable source. It is also the longest established forest certification scheme and is recommended by
          bodies such as WWF and UK Government.
        </p>
      </span>
    </AboutContainer>
  );
};

export default About;
