import React from 'react';
import { Container } from '../../App.styles';
import { HomeBody, HomeImgContainer, HomeImgHolder } from './Home.styles';

const Home = () => {
  return (
    <Container>
      <HomeBody>
        50 Smoothie Recipe Cards
      </HomeBody>
      <HomeImgContainer>
        <HomeImgHolder>
          <img alt="Smoothie1" src="/img/image1.jpg" />
        </HomeImgHolder>
        <HomeImgHolder>
          <img alt="Smoothie2" src="/img/image2.jpg" />
        </HomeImgHolder>
        <HomeImgHolder>
          <img alt="Smoothie3" src="/img/image3.jpg" />
        </HomeImgHolder>
        <HomeImgHolder>
          <img alt="Smoothie4" src="/img/image4.jpg" />
        </HomeImgHolder>
        <HomeImgHolder>
          <img alt="Smoothie5" src="/img/image5.jpg" />
        </HomeImgHolder>
        <HomeImgHolder>
          <img alt="Smoothie6" src="/img/image6.jpg" />
        </HomeImgHolder>
      </HomeImgContainer>
    </Container>
  );
};

export default Home;
