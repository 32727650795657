import styled from 'styled-components';

export const HomeImgContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
`;

export const HomeBody = styled.h3`
  font-family: 'Didot-italic';
  font-weight: normal;
  font-size: 18px;
  color: #a88442;
  margin-top: -30px;
  width: 100%;
  text-align: center;
`;

export const HomeImgHolder = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
  }

  &:first-child {
    grid-column: span 2;
  }

  &:nth-child(n + 2):nth-child(-n + 3) {
    grid-column: span 1;
    padding-top: 133.35%;
  }
  &:nth-child(n + 4) {
    grid-column: span 2;
  }
  &:nth-child(n + 5) {
    grid-column: span 1;
    padding-top: 133.35%;
  }
`;
