import styled from 'styled-components';
import { Container } from '../../App.styles';

export const WholesaleContainer = styled(Container)`
  position: relative;
`;

export const PriceList = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: white;
    color: #a88442;
    padding: 0 20px;
  }
  li:first-child {
    background-color: transparent;
    font-weight: 700;
  }
`;
