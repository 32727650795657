import styled from 'styled-components';

export const FooterContainer = styled.footer`
  position: relative;
  align-self: end;
  width: 100%;
  display: flex;
  font-size: 10px;
  justify-content: flex-end;
  padding: 20px 20px 10px;
`