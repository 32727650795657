import styled from 'styled-components';
import { Container } from '../../App.styles';

export const AboutContainer = styled(Container)`
  position: relative;
  padding: 0;
  span {
    display: block;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    bottom: 0;
    padding: 20px;
  }

  @media (min-width: 1367px) {
    span {
      position: absolute;
      left: 0;
      background-color: rgba(255, 255, 255, 0.7);
      width: 100%;
      bottom: 0;
      padding: 20px;
    }
    h1 {
      margin-top: 0;
      color: #a88442;
      text-transform: uppercase;
    }
  }
`;

export const AboutImgContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 50%;

  img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
`;
