import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Hamburger from '../Hamburger';
import { NavContainer, NavLinks, NavLinksContainer } from './Nav.styles';

const Nav = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <NavContainer>
      <NavLinksContainer>
        <button
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <Hamburger expanded={isExpanded} />
        </button>
        <NavLinks>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/tutorial">Tutorial</Link>
          </li>
          <li>
            <Link to="/wholesale">Wholesale</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </NavLinks>
      </NavLinksContainer>
      <NavLinks isMobile isExpanded={isExpanded}>
        <li>
          <Link to="/" onClick={() => setIsExpanded(false)}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={() => setIsExpanded(false)}>
            About
          </Link>
        </li>
        <li>
          <Link to="/tutorial" onClick={() => setIsExpanded(false)}>
            Tutorial
          </Link>
        </li>
        <li>
          <Link to="/wholesale" onClick={() => setIsExpanded(false)}>
            Wholesale
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={() => setIsExpanded(false)}>
            Contact
          </Link>
        </li>
      </NavLinks>
    </NavContainer>
  );
};

export default Nav;
