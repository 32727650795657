import React from 'react';
import { Container } from '../../App.styles';
import { StyledVideo } from './Tutorial.styles';

const Tutorial = () => {
  return (
    <Container>
      <StyledVideo src="/video/blendandsip_demo.mp4" autoPlay loop playsInline controls>

      </StyledVideo>
    </Container>
  );
};

export default Tutorial;