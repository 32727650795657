import { BrowserRouter as Router, Route } from 'react-router-dom';
import GlobalStyles from './App.styles';
import Footer from './components/Footer';
import Logo from './components/Logo';
import Nav from './components/Nav';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Tutorial from './pages/Tutorial';
import Wholesale from './pages/Wholesale';

function App() {
  return (
    <>
      <GlobalStyles />
      <div className="App">
        <Router>
        <header>
            <Nav />
            <Logo />
          </header>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/wholesale">
            <Wholesale />
          </Route>
          <Route path="/about">
            <About />
          </Route> 
          <Route path="/tutorial">
            <Tutorial />
          </Route> 
          <Route path="/contact">
            <Contact />
          </Route> 
          <Footer />
        </Router>
      </div>
    </>
  );
}

export default App;
