import React from 'react';
import { ContactBody, ContactContainer } from './Contact.styles';

const Contact = () => {
  return (
    <ContactContainer>
      <ContactBody>
        <div>
          <img alt="email icon" src="/img/email.svg" />
          <a href="mailto:info@blendandsip.co.uk">Contact by Email</a>
        </div>
        <div>
          <img alt="email icon" src="/img/phone.svg" />
          <a href="tel:+447846495307">Contact by Phone</a>
        </div>
      </ContactBody>
    </ContactContainer>
  );
};

export default Contact;
