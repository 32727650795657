import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LogoContainer = styled.div`
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  img {
    width: 100%;
  }
`;

const Logo = () => {
  return (
    <LogoContainer>
      <Link to="/">
        <img alt="Logo" src="/img/logo.svg" />
      </Link>
    </LogoContainer>
  );
};

export default Logo;
